<script>
import { isNil, isString } from 'lodash'

import { toastMixins } from '@/mixins/toast'
import authService from '@/services/auth/auth'

export default {
  mixins: [toastMixins],
  data() {
    return {
      email: null,
      companyOptions: [],
      companyId: null,
      databaseOptions: [],
      databaseId: null,
      username: null,
      password: null,
      isLoading: false,
      errorMessage: null
    }
  },
  computed: {
    emailFieldEntered() {
      return this.email && this.email.trim().length > 0
    },
    companyFieldEntered() {
      return isNil(this.companyId)
    },
    databaseFieldEntered() {
      return isNil(this.databaseId)
    },
    isReadyToLogin() {
      const validEmail = this.email && this.email.trim().length > 0
      const validUsername = this.username && this.username.trim().length > 0
      const validPassword = this.password && this.password.trim().length > 0
      return (
        validEmail &&
        this.companyId &&
        this.databaseId &&
        validUsername &&
        validPassword
      )
    }
  },
  watch: {
    companyId() {
      if (!isNil(this.companyId)) {
        this.fetchDatabaseChoices()
      }
    }
  },
  methods: {
    async fetchCompanyChoices() {
      this.isLoading = true
      const querystring = `email=${this.email}`
      try {
        const res = await authService.getCompanyChoices(querystring)
        const { choices } = res.data.data
        this.companyOptions = choices.map((el) => ({
          ...el,
          text: el.name,
          value: el.id
        }))
      } catch (error) {
        this.errorMessage = error.response.data.thMessage
      }
      this.isLoading = false
    },
    async fetchDatabaseChoices() {
      this.isLoading = true
      const selectedCompany = this.companyOptions.find(
        (company) => company.id === this.companyId
      )
      if (selectedCompany) {
        const querystring = `email=${this.email}&companyCode=${selectedCompany.code}`
        try {
          const res = await authService.getDatabaseChoices(querystring)
          const { choices } = res.data.data
          this.databaseOptions = choices.map((el) => ({
            ...el,
            text: el.displayName,
            value: el.id
          }))
        } catch (error) {
          this.errorMessage = error.response.data.thMessage
        }
      }
      this.isLoading = false
    },
    async loginButtonClicked() {
      this.isLoading = true
      try {
        const loginObj = {
          email: isString(this.email) ? this.email.trim() : null,
          companyId: this.companyId,
          databaseId: this.databaseId,
          username: isString(this.username) ? this.username.trim() : null,
          password: isString(this.password) ? this.password.trim() : null
        }
        await this.$store.dispatch('login', loginObj)
        this.$router.replace('/')
      } catch (error) {
        this.errorMessage = error.response.data.thMessage
      }
      this.isLoading = false
    },
    resetButtonClicked() {
      this.email = null
      this.companyOptions = []
      this.companyId = null
      this.databaseOptions = []
      this.databaseId = null
      this.username = null
      this.password = null
      this.isLoading = false
      this.errorMessage = null
    }
  }
}
</script>

<template>
  <b-container
    class="d-flex justify-content-center align-items-center h-100"
    fluid
  >
    <!-- login content -->
    <b-row class="w-100" align-h="center">
      <b-col
        cols="12"
        sm="10"
        md="8"
        lg="6"
        xl="5"
        class="d-flex justify-content-center align-items-center m-0 p-0"
      >
        <b-card style="width: 100%; padding: 1rem">
          <b-row class="text-center">
            <b-col class="program-name">
              <span>Happysoft Accounting Cloud</span>
            </b-col>
          </b-row>
          <b-row>
            <!-- email -->
            <b-col class="col-12 d-flex flex-column mt-3">
              <label for="email">อีเมล</label>
              <b-form-input
                v-model="email"
                type="text"
                placeholder="อีเมล"
                @keydown.enter="fetchCompanyChoices()"
              ></b-form-input>
            </b-col>

            <!-- companyId -->
            <b-col
              v-if="companyOptions.length > 0"
              class="col-12 d-flex flex-column mt-3"
            >
              <label for="companyId">บริษัท</label>
              <b-select
                v-model="companyId"
                type="text"
                placeholder="เลือกบริษัท"
                :options="companyOptions"
              ></b-select>
            </b-col>

            <!-- databaseId -->
            <b-col
              v-if="databaseOptions.length > 0"
              class="col-12 d-flex flex-column mt-3"
            >
              <label for="databaseId">ฐานข้อมูล</label>
              <b-select
                v-model="databaseId"
                type="text"
                placeholder="เลือกฐานข้อมูล"
                :options="databaseOptions"
              ></b-select>
            </b-col>

            <!-- username -->
            <b-col
              v-if="companyId !== null && databaseId !== null"
              class="col-12 d-flex flex-column mt-3"
            >
              <label for="username">ชื่อผู้ใช้งาน</label>
              <b-form-input
                v-model="username"
                type="text"
                placeholder="ชื่อผู้ใช้งาน"
              ></b-form-input>
            </b-col>

            <!-- password -->
            <b-col
              v-if="companyId !== null && databaseId !== null"
              class="col-12 d-flex flex-column mt-3"
            >
              <label for="password">รหัสผ่าน</label>
              <b-form-input
                v-model="password"
                type="password"
                placeholder="รหัสผ่าน"
                @keydown.enter="loginButtonClicked()"
              ></b-form-input>
            </b-col>

            <!-- buttons -->
            <b-col class="col-12 d-flex justify-content-center mt-4">
              <!-- email confirm -->
              <b-button
                v-if="
                  companyOptions.length === 0 && databaseOptions.length === 0
                "
                variant="primary"
                @click="fetchCompanyChoices()"
                :disabled="isLoading || !emailFieldEntered"
              >
                <span>ยืนยันอีเมล</span>
                <b-spinner
                  v-if="isLoading"
                  class="ml-2"
                  variant="info"
                  label="Spinning"
                  small
                ></b-spinner>
              </b-button>

              <!-- login -->
              <b-button
                v-if="companyId && databaseId"
                variant="primary"
                @click="loginButtonClicked()"
                :disabled="isLoading || !isReadyToLogin"
              >
                <span>เข้าสู่ระบบ</span>
                <b-spinner
                  v-if="isLoading"
                  class="ml-2"
                  variant="info"
                  label="Spinning"
                  small
                ></b-spinner>
              </b-button>

              <!-- reset -->
              <b-button
                variant="danger"
                @click="resetButtonClicked()"
                :disabled="isLoading"
                class="ml-3"
              >
                <span>ล้างข้อมูล</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- error modal -->
    <error-modal
      :displayProp="!!errorMessage"
      :errorMessageProp="errorMessage"
      @modalClosed="errorMessage = null"
    ></error-modal>
  </b-container>
</template>

<style scoped>
.style-row {
  width: 50%;
}
.program-name {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 10px 0px 30px 0px;
}
.error-message {
  color: red;
  font-size: 0.9rem;
}
</style>
